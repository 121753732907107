import script from "./Home.vue?vue&type=script&setup=true&lang=js"
export * from "./Home.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QScrollArea,QCarousel,QCarouselSlide,QSeparator,QBreadcrumbs,QBreadcrumbsEl,QImg,QInfiniteScroll,QSpinnerDots});
