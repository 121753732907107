<script setup>
import { defineProps } from "vue";
import useUtils from "../../Composables/useUtils";
import { useQuasar } from "quasar";
import useLeilao from "../../Composables/useLeilao";
import useGlobals from "../../Composables/useGlobals";
import useLeilaoLocalComUpdates from "../../Composables/useLeilaoLocalComUpdates";

const props = defineProps({
    leilaoInit: Object,
});

const { leilao } = useLeilaoLocalComUpdates(props);

const {
    abrirLeilao,
    abrirLeilaoNovaAba,
    fullscreen,
    autoplay,
    slide,
    favoritado,
    timer,
} = useLeilao(leilao);

const { getImagem, formatDateWithoutSeconds, formatador, stripHTML } =
    useUtils();

const $q = useQuasar();

const { logoLeiloeiro } = useGlobals();
</script>

<template>
    <!--    <div class="q-pa-sm col col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2">-->
    <div
        class="q-pa-sm"
        :style="
            {
                xl: 'width: 20%',
                lg: 'width: 20%',
                md: 'width: 25%',
                sm: 'width: 50%',
                xs: 'width: 100%',
            }[$q.screen.name]
        "
    >
        <q-card
            class="cursor-pointer"
            :style="$q.screen.lt.sm ? 'width:100%' : ''"
        >
            <q-card-section
                :style="`background-color: ${leilao.status_leilao?.cor_site};`"
                class="text-subtitle1 text-white text-center q-py-none column justify-center"
                style="height: 2em"
                @click="abrirLeilao()"
                @auxclick="abrirLeilaoNovaAba()"
            >
                {{ leilao.status_leilao?.nome }}
            </q-card-section>
            <q-responsive :ratio="16 / 11" style="width: 100%">
                <q-carousel
                    v-model="slide"
                    v-model:fullscreen="fullscreen"
                    :autoplay="autoplay"
                    :thumbnails="fullscreen"
                    animated
                    arrows
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    height="14em"
                    infinite
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    swipeable
                    @mouseenter="autoplay = false"
                    @mouseleave="autoplay = true"
                >
                    <template v-slot:control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="top-right"
                        >
                            <q-btn
                                :icon="
                                    fullscreen
                                        ? 'mdi-arrow-u-left-top'
                                        : 'mdi-fullscreen'
                                "
                                color="white"
                                push
                                text-color="primary"
                                @click.stop="fullscreen = !fullscreen"
                                >{{ fullscreen ? "Voltar" : "" }}
                            </q-btn>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-right"
                            v-if="leilao.views && +leilao.externo !== 1"
                        >
                            <q-badge size="sm" color="positive" rounded>
                                <q-icon
                                    name="mdi-eye"
                                    size="xs"
                                    class="q-mr-xs"
                                />{{ leilao.views }}
                            </q-badge>
                        </q-carousel-control>
                    </template>
                    <q-carousel-slide
                        v-for="(imagem, key) in leilao?.imagens_leilao &&
                        leilao?.imagens_leilao.length > 0
                            ? leilao.imagens_leilao
                            : leilao.comitente.arquivo_capa_padrao
                            ? [
                                  {
                                      arquivo:
                                          leilao.comitente.arquivo_capa_padrao,
                                  },
                              ]
                            : []"
                        :key="key"
                        :name="1 + key"
                        style="
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                        "
                        @click="abrirLeilao()"
                        @auxclick="abrirLeilaoNovaAba()"
                        class="q-pa-none"
                    >
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="
                                getImagem(
                                    imagem?.arquivo,
                                    fullscreen,
                                    leilao.status_leilao?.identificador
                                )
                            "
                            :ratio="16 / 11"
                        >
                            <template v-slot:error>
                                <div
                                    class="absolute-full flex flex-center bg-primary text-white"
                                >
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div
                                    class="absolute-full flex column flex-center bg-white text-primary"
                                >
                                    <img
                                        :src="logoLeiloeiro"
                                        style="width: 40%"
                                    />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" />
                                        Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                        <div
                            v-if="fullscreen"
                            class="absolute-bottom custom-caption q-pb-xl"
                        >
                            <q-card
                                class="q-ma-lg q-ma-md-xl q-pa-md"
                                style="overflow-y: scroll; max-height: 15vh"
                            >
                                <div class="text-h5">{{ leilao.titulo }}</div>
                                <div
                                    class="text-subtitle2"
                                    v-html="leilao.descricao"
                                ></div>
                            </q-card>
                        </div>
                    </q-carousel-slide>
                </q-carousel>
            </q-responsive>
            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="q-py-xs"
                @click="abrirLeilao()"
                @auxclick="abrirLeilaoNovaAba()"
            >
                <div
                    class="row no-wrap items-center"
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <div class="col text-h6 ellipsis text-primary">
                        {{ leilao.titulo }}
                    </div>
                    <q-tooltip> Código de leilão {{ leilao.id }} </q-tooltip>
                </div>
                <div
                    class="row no-wrap items-center"
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <div
                        class="col text-subtitle1 ellipsis text-grey-10"
                        v-html="
                            (stripHTML(leilao?.descricao?.toString())?.slice(
                                0,
                                35
                            ) || 'Sem Descrição') +
                            (stripHTML(leilao?.descricao?.toString())?.length >
                            35
                                ? '...'
                                : '')
                        "
                    ></div>
                    <q-tooltip>
                        <div v-html="leilao?.descricao"></div>
                    </q-tooltip>
                </div>
                <q-separator class="q-mt-sm" />
                <div
                    class="row no-wrap items-center"
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <div class="col text-subtitle2 text-primary text-center">
                        <template v-if="+leilao?.venda_direta === 1"
                            >Venda Direta: {{ leilao.id }}</template
                        >
                        <template v-else-if="+leilao?.proposta === 1"
                            >Evento: {{ leilao.id }}</template
                        >
                        <template v-else
                            >Nº do Leilão: {{ leilao.id }}</template
                        >
                    </div>
                </div>
                <q-separator />
            </q-card-section>

            <q-list>
                <q-item
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar" />
                        <q-icon
                            size="sm"
                            color="positive"
                            name="mdi-currency-usd"
                        />
                    </q-item-section>

                    <q-item-section
                        class="text-body2"
                        :style="
                            +leilao.dois_leiloes !== 1
                                ? 'min-height: 125px;'
                                : 'min-height: 55px'
                        "
                    >
                        <q-item-label
                            :style="
                                +leilao.primeiro_leilao_concluido === 1
                                    ? 'text-decoration: line-through'
                                    : ''
                            "
                        >
                            <template
                                v-if="
                                    +leilao?.venda_direta === 1 ||
                                    +leilao?.proposta === 1
                                "
                            >
                                Até:
                            </template>
                            <template v-else>
                                <template v-if="leilao.dois_leiloes === '1'"
                                    >1º</template
                                >

                                <template v-if="+leilao?.proposta === 1">
                                    Evento -
                                </template>
                                <template v-else> Leilão </template>
                            </template>
                            {{
                                formatDateWithoutSeconds(
                                    leilao.data_hora_inicio
                                )
                            }}
                        </q-item-label>
                        <q-item-label
                            :style="
                                +leilao.primeiro_leilao_concluido === 1
                                    ? 'text-decoration: line-through'
                                    : ''
                            "
                            class="text-body2 text-grey-8"
                            v-if="+leilao.externo === 1"
                            >Entre e confira os valores.</q-item-label
                        >
                        <q-item-label
                            :style="
                                +leilao.primeiro_leilao_concluido === 1
                                    ? 'text-decoration: line-through'
                                    : ''
                            "
                            class="text-body2 text-grey-8"
                            v-if="
                                +leilao.externo !== 1 && +leilao.lotes_count > 0
                            "
                        >
                            <template v-if="+leilao.menorlanceInicial > 0">
                                <template v-if="+leilao?.venda_direta === 1">
                                    A partir:
                                </template>
                                <template v-else>
                                    Lote<template v-if="+leilao.lotes_count > 1"
                                        >s</template
                                    >
                                    a partir:
                                </template>
                                {{
                                    formatador.format(leilao.menorlanceInicial)
                                }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    v-if="leilao.dois_leiloes === '1'"
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar" />
                        <q-icon
                            size="sm"
                            color="positive"
                            name="mdi-currency-usd"
                        />
                    </q-item-section>

                    <q-item-section class="text-body2" style="min-height: 55px">
                        <q-item-label
                            :style="
                                +leilao.segundo_leilao_concluido === 1
                                    ? 'text-decoration: line-through'
                                    : ''
                            "
                            >2º Leilão -
                            {{
                                formatDateWithoutSeconds(
                                    leilao.data_hora_inicio_segundo_leilao
                                )
                            }}</q-item-label
                        >
                        <q-item-label
                            class="text-body2 text-grey-8"
                            v-if="+leilao.externo === 1"
                            >Entre e confira os valores.</q-item-label
                        >
                        <q-item-label
                            class="text-body2 text-grey-8"
                            v-if="
                                +leilao.externo !== 1 && +leilao.lotes_count > 0
                            "
                        >
                            <template
                                v-if="+leilao.menorlanceInicialSegLeilao > 0"
                            >
                                Lote<template v-if="+leilao.lotes_count > 1"
                                    >s</template
                                >
                                a partir:
                                {{
                                    formatador.format(
                                        leilao.menorlanceInicialSegLeilao
                                    )
                                }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    v-if="+leilao.externo === 1"
                    clickable
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon
                            color="positive"
                            :name="
                                +leilao.tipo_leilao === 6
                                    ? 'mdi-scale-balance'
                                    : 'mdi-handshake'
                            "
                        />
                    </q-item-section>

                    <q-item-section>
                        <q-item-label>{{
                            +leilao.tipo_leilao === 6
                                ? "Leilão TRF"
                                : "Leilão Parceiro"
                        }}</q-item-label>
                        <q-item-label caption>{{
                            +leilao.tipo_leilao === 6
                                ? "Este leilão será feito em site externo"
                                : "O leilão será feito em site parceiro"
                        }}</q-item-label>
                    </q-item-section>
                    <q-tooltip>
                        {{
                            +leilao.tipo_leilao === 6
                                ? "O site externo será aberto numa nova guia"
                                : "O site parceiro será aberto numa nova guia"
                        }}
                    </q-tooltip>
                </q-item>
                <q-item
                    v-else
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon color="primary" name="mdi-package-variant" />
                    </q-item-section>

                    <q-item-section>
                        <q-tooltip>
                            Leilão aberto com {{ leilao.lotes_count }} lotes,
                            {{ leilao.lotes_vendidos }} já foram vendidos,
                            {{ leilao.lotes_cancelados }} cancelado(s),
                            {{ leilao.lotes_retirados }} retirado(s)
                        </q-tooltip>
                        <q-item-label
                            >{{ leilao.lotes_count }} Lotes</q-item-label
                        >
                        <q-item-label caption>
                            {{ leilao.lotes_vendidos }} Lotes Vendidos
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <q-separator />

            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                @click="abrirLeilao()"
                @auxclick="abrirLeilaoNovaAba()"
            >
                <q-item
                    class="text-center text-primary"
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <q-item-section
                        v-if="
                            +leilao?.venda_direta === 1 ||
                            +leilao?.proposta === 1
                        "
                    >
                        <q-item-label
                            overline
                            :class="'text-' + leilao.proximoStatus.cor"
                        >
                            Aceitando
                        </q-item-label>
                        <q-item-label class="text-bold">
                            Propostas
                        </q-item-label>
                    </q-item-section>

                    <q-item-section v-else>
                        <q-item-label
                            overline
                            :class="'text-' + leilao.proximoStatus.cor"
                        >
                            {{ leilao.proximoStatus.texto }}
                        </q-item-label>
                        <q-item-label
                            v-if="leilao.proximoStatus.timestamp"
                            class="text-bold"
                        >
                            {{ timer }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-card-section>

            <q-separator />

            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                @click="abrirLeilao()"
                @auxclick="abrirLeilaoNovaAba()"
            >
                <q-tooltip>
                    Leilão Oficial {{ leilao?.comitente?.nome_fantasia }}
                </q-tooltip>
                <q-responsive :ratio="60 / 17" style="height: 3em">
                    <q-img
                        v-if="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        fit="contain"
                    >
                    </q-img>
                    <div
                        class="flex text-primary items-center justify-center text-center"
                        v-else
                    >
                        {{ leilao?.comitente?.nome_fantasia }}
                    </div>
                </q-responsive>
            </q-card-section>

            <q-separator />

            <q-card-actions
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center"
                @click="abrirLeilao()"
                @auxclick="abrirLeilaoNovaAba()"
            >
                <q-btn
                    :icon="favoritado ? 'mdi-heart' : 'mdi-heart-outline'"
                    color="red"
                    flat
                    ripple
                    size="md"
                    stretch
                    @click.stop="
                        () => {
                            favoritado = !favoritado;
                        }
                    "
                >
                    {{ favoritado ? "Favoritado" : "Favoritar" }}
                    <q-tooltip>
                        {{
                            favoritado
                                ? "Remover este leilão de sua lista de favoritos"
                                : "Adicione este leilão aos seus favoritos, e não perca a ação!"
                        }}
                    </q-tooltip>
                </q-btn>
                <q-btn
                    v-if="+leilao.externo === 1"
                    color="positive"
                    flat
                    icon="mdi-link"
                    ripple
                    size="md"
                    stretch
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    Ver Leilão
                    <q-tooltip> Clique aqui para acessar o leilão </q-tooltip>
                </q-btn>
                <q-btn
                    v-else
                    color="primary"
                    flat
                    icon="mdi-package-variant"
                    ripple
                    size="md"
                    stretch
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <template v-if="+leilao?.venda_direta === 1">
                        Ver Auto Sato
                    </template>
                    <template v-else> Ver Lotes </template>

                    <q-tooltip>
                        Clique aqui para ver os lotes deste leilão
                    </q-tooltip>
                </q-btn>
            </q-card-actions>
        </q-card>
    </div>
</template>
