<script setup>
import { defineProps } from "vue";
import useUtils from "../../Composables/useUtils";
import useLote from "../../Composables/useLote";
import useGlobals from "../../Composables/useGlobals";
import useLoteLocalComUpdates from "../../Composables/useLoteLocalComUpdates";
import useLeilaoLocalComUpdates from "../../Composables/useLeilaoLocalComUpdates";

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    mostrarLeilao: Boolean,
});

const { lote } = useLoteLocalComUpdates(props);

const { leilao } = useLeilaoLocalComUpdates(props);

const {
    getImagem,
    formatDateWithoutSeconds,
    formatador,
    formatadorLote,
    stripHTML,
} = useUtils();

const {
    abrirLote,
    abrirLoteNovaAba,
    abrirLeilao,
    abrirLeilaoNovaAba,
    fullscreen,
    autoplay,
    slide,
    timer,
    timerModerador,
} = useLote(leilao, lote);

const { logoLeiloeiro } = useGlobals();
</script>

<template>
    <div
        class="q-pa-sm"
        :style="
            {
                xl: 'width: 20%',
                lg: 'width: 20%',
                md: 'width: 25%',
                sm: 'width: 50%',
                xs: 'width: 100%',
            }[$q.screen.name]
        "
    >
        <!--    <div class="q-pa-sm col col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3" style="justify-content: stretch">-->
        <q-card
            class="cursor-pointer"
            :style="$q.screen.lt.sm ? 'width:100%' : ''"
        >
            <q-card-section
                :style="`background-color: ${lote.status_lote?.cor_site};`"
                class="text-subtitle1 text-white text-center q-py-none column justify-center"
                style="height: 2em"
                @click="abrirLote()"
                @auxclick="abrirLoteNovaAba()"
            >
                {{ lote.status_lote?.nome }}
            </q-card-section>
            <q-responsive :ratio="16 / 11" style="width: 100%">
                <q-carousel
                    v-model="slide"
                    v-model:fullscreen="fullscreen"
                    :autoplay="autoplay"
                    :thumbnails="fullscreen"
                    animated
                    arrows
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    height="14em"
                    infinite
                    swipeable
                >
                    <template v-slot:control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="top-right"
                        >
                            <q-btn
                                :icon="
                                    fullscreen
                                        ? 'mdi-arrow-u-left-top'
                                        : 'mdi-fullscreen'
                                "
                                color="white"
                                push
                                text-color="primary"
                                @click.stop="fullscreen = !fullscreen"
                                >{{ fullscreen ? "Voltar" : "" }}
                            </q-btn>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-left"
                            v-if="+lote.vistoria_imobiliaria?.desocupado"
                        >
                            <q-badge size="sm" color="primary" rounded>
                                <q-icon
                                    name="mdi-home-export-outline"
                                    size="xs"
                                    class="q-mr-xs"
                                />
                                Desocupado
                            </q-badge>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-left"
                            v-if="+lote.vistoria_veicular?.sucata"
                        >
                            <q-badge size="sm" color="primary" rounded>
                                <q-icon
                                    name="mdi-car-wrench"
                                    size="xs"
                                    class="q-mr-xs"
                                />
                                Sucata
                            </q-badge>
                        </q-carousel-control>
                        <q-carousel-control
                            :offset="[10, 10]"
                            position="bottom-right"
                            v-if="lote.views"
                        >
                            <q-badge size="sm" color="positive" rounded>
                                <q-icon
                                    name="mdi-eye"
                                    size="xs"
                                    class="q-mr-xs"
                                />{{ lote.views }}
                            </q-badge>
                        </q-carousel-control>
                    </template>
                    <q-carousel-slide
                        v-for="(imagem, key) in lote.imagens_lote &&
                        lote.imagens_lote.length > 0
                            ? lote.imagens_lote
                            : leilao.comitente.arquivo_capa_padrao
                            ? [
                                  {
                                      arquivo:
                                          leilao.comitente.arquivo_capa_padrao,
                                  },
                              ]
                            : []"
                        :key="key"
                        :name="1 + key"
                        style="
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                        "
                        @click="abrirLote()"
                        @auxclick="abrirLoteNovaAba()"
                        class="q-pa-none"
                    >
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="
                                getImagem(
                                    imagem?.arquivo,
                                    fullscreen,
                                    leilao.status_leilao?.identificador
                                )
                            "
                            :ratio="16 / 11"
                        >
                            <template v-slot:error>
                                <div
                                    class="absolute-full flex flex-center bg-primary text-white"
                                >
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div
                                    class="absolute-full flex column flex-center bg-white text-primary"
                                >
                                    <img
                                        :src="logoLeiloeiro"
                                        style="width: 40%"
                                    />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" />
                                        Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                        <div
                            v-if="fullscreen"
                            class="absolute-bottom custom-caption q-pb-xl"
                        >
                            <q-card
                                class="q-ma-lg q-ma-md-xl q-pa-md"
                                style="overflow-y: scroll; max-height: 15vh"
                            >
                                <div class="text-h5">{{ lote.titulo }}</div>
                                <div
                                    class="text-subtitle2"
                                    v-html="lote.descricao"
                                ></div>
                            </q-card>
                        </div>
                        <div v-else class="absolute-bottom seq-caption q-pb-xl">
                            <h5>{{ formatadorLote(lote.sequencia) }}</h5>
                        </div>
                    </q-carousel-slide>
                </q-carousel>
            </q-responsive>
            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="q-py-xs"
                @click="abrirLote()"
                @auxclick="abrirLoteNovaAba()"
            >
                <div
                    class="row no-wrap items-center"
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <div
                        class="col desc-scroll text-h6"
                        style="max-width: 83vw"
                    >
                        <p
                            class="q-mb-none"
                            style="
                                line-height: 1.5em;
                                height: 3em;
                                overflow: hidden;
                            "
                        >
                            {{ stripHTML(lote.titulo) }}
                        </p>
                        <q-tooltip>
                            {{ formatadorLote(lote.sequencia) }}
                            {{ stripHTML(lote.titulo) }}
                        </q-tooltip>
                    </div>
                </div>
                <q-separator class="q-mt-sm" v-if="mostrarLeilao" />
                <div
                    class="row no-wrap text-center"
                    v-if="mostrarLeilao"
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <div
                        class="col text-subtitle2 text-primary text-center"
                        v-if="mostrarLeilao"
                    >
                        <template v-if="+leilao?.venda_direta === 1"
                            >Venda Direta: {{ leilao.id }}</template
                        >
                        <template v-else-if="+leilao?.proposta === 1"
                            >Evento: {{ leilao.id }}</template
                        >
                        <template v-else
                            >Nº do Leilão: {{ leilao.id }}</template
                        >
                    </div>
                </div>
                <q-separator />
            </q-card-section>

            <q-list>
                <q-item
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar" />
                        <q-icon
                            size="sm"
                            color="positive"
                            name="mdi-currency-usd"
                        />
                    </q-item-section>

                    <q-item-section
                        class="text-body2"
                        :style="
                            +leilao.primeiro_leilao_concluido === 1
                                ? 'text-decoration: line-through'
                                : ''
                        "
                        style="min-height: 55px"
                    >
                        <q-item-label>
                            <template
                                v-if="
                                    +leilao?.venda_direta === 1 ||
                                    +leilao?.proposta === 1
                                "
                            >
                                Fechamento:
                            </template>
                            <template v-else>
                                <template v-if="leilao.dois_leiloes === '1'"
                                    >1º</template
                                >
                                Leilão -
                            </template>
                            {{
                                formatDateWithoutSeconds(
                                    leilao.data_hora_inicio
                                )
                            }}
                        </q-item-label>

                        <q-item-label class="text-body2 text-grey-8">
                            <template
                                v-if="
                                    +leilao?.venda_direta === 1 ||
                                    +leilao?.proposta === 1
                                "
                            >
                                Valor de Referência:
                                {{ formatador.format(lote.lance_inicial) }}
                            </template>
                            <template v-else-if="+lote.lance_inicial > 0">
                                Lance inicial:
                                {{ formatador.format(lote.lance_inicial) }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    v-if="leilao.dois_leiloes === '1'"
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                        <q-icon size="sm" color="primary" name="mdi-calendar" />
                        <q-icon
                            size="sm"
                            color="positive"
                            name="mdi-currency-usd"
                        />
                    </q-item-section>

                    <q-item-section class="text-body2" style="min-height: 55px">
                        <q-item-label
                            :style="
                                +leilao.segundo_leilao_concluido === 1
                                    ? 'text-decoration: line-through'
                                    : ''
                            "
                            >2º Leilão -
                            {{
                                formatDateWithoutSeconds(
                                    leilao.data_hora_inicio_segundo_leilao
                                )
                            }}</q-item-label
                        >
                        <q-item-label class="text-body2 text-grey-8">
                            <template
                                v-if="+lote.lance_inicial_segundo_leilao > 0"
                            >
                                Lance inicial:
                                {{
                                    formatador.format(
                                        lote.lance_inicial_segundo_leilao
                                    )
                                }}
                            </template>
                            <template v-else>Entre e confira.</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    v-else
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section avatar class="items-center">
                    </q-item-section>

                    <q-item-section class="text-body2" style="min-height: 55px">
                    </q-item-section>
                </q-item>
            </q-list>

            <q-separator />

            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                v-if="
                    +leilao.fechamento_automatico === 1 &&
                    +leilao.tipo_fechamento === 1
                "
                @click="abrirLote()"
                @auxclick="abrirLoteNovaAba()"
            >
                <q-item
                    class="text-center text-primary"
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section>
                        <q-item-label
                            overline
                            :class="'text-' + lote.proximoStatus.cor"
                        >
                            {{ lote.proximoStatus.texto }}
                        </q-item-label>
                        <q-item-label
                            v-if="lote.proximoStatus.timestamp"
                            class="text-bold"
                        >
                            {{ timer }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-card-section>

            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                v-if="
                    +leilao.fechamento_automatico === 0 &&
                    lote.timer_moderador &&
                    timerModerador
                "
                @click="abrirLote()"
                @auxclick="abrirLoteNovaAba()"
            >
                <q-item
                    class="text-center text-primary"
                    :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                    clickable
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    <q-item-section>
                        <q-item-label class="text-bold">
                            {{ timerModerador }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-card-section>

            <q-separator />

            <q-card-section
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center q-pa-xs"
                @click="abrirLote(leilao.id)"
            >
                <q-tooltip>
                    Leilão Oficial {{ leilao?.comitente?.nome_fantasia }}
                </q-tooltip>
                <q-responsive :ratio="60 / 17" style="height: 3em">
                    <q-img
                        v-if="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                        fit="contain"
                    >
                    </q-img>
                    <div
                        class="flex text-primary items-center justify-center text-center"
                        v-else
                    >
                        {{ leilao?.comitente?.nome_fantasia }}
                    </div>
                </q-responsive>
            </q-card-section>

            <q-separator />

            <q-card-actions
                v-if="mostrarLeilao"
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center"
            >
                <q-btn
                    v-if="mostrarLeilao"
                    color="primary"
                    flat
                    icon="mdi-package-variant-plus"
                    ripple
                    size="md"
                    stretch
                    @click="abrirLeilao()"
                    @auxclick="abrirLeilaoNovaAba()"
                >
                    <template
                        v-if="
                            +leilao?.venda_direta === 1 ||
                            +leilao?.proposta === 1
                        "
                    >
                        Evento Completo
                        <q-tooltip>
                            Clique aqui para ver todos os lotes deste evento de
                            Venda Direta
                        </q-tooltip>
                    </template>
                    <template v-else>
                        Leilão Completo
                        <q-tooltip>
                            Clique aqui para ver todos os lotes deste leilão
                        </q-tooltip>
                    </template>
                </q-btn>
                <q-btn
                    color="primary"
                    flat
                    icon="mdi-package-variant"
                    ripple
                    size="md"
                    stretch
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    Ver Lote
                    <q-tooltip>
                        Clique aqui para ver os detalhes do lote
                    </q-tooltip>
                </q-btn>
            </q-card-actions>
            <q-card-actions
                v-else
                :class="$q.dark.isActive ? 'bg-black' : 'bg-white'"
                class="justify-center"
                @click="abrirLeilao()"
                @auxclick="abrirLoteNovaAba()"
            >
                <q-btn
                    color="primary"
                    flat
                    icon="mdi-package-variant"
                    ripple
                    size="md"
                    stretch
                    @click="abrirLote()"
                    @auxclick="abrirLoteNovaAba()"
                >
                    Ver Detalhes
                    <q-tooltip>
                        Clique aqui para ver os detalhes do lote
                    </q-tooltip>
                </q-btn>
            </q-card-actions>
        </q-card>
    </div>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.desc-scroll::-webkit-scrollbar
    display: none
</style>
<style>
.cssmarquee {
    overflow: hidden;
    position: relative;
    height: 35px;
}
.cssmarquee p {
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    text-align: center;
    overflow-x: visible;
    transform: translateX(0%);
    animation: cssmarquee 60s linear infinite;
}
@keyframes cssmarquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>
