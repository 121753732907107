<script setup>
import { defineProps } from 'vue';
import { Head } from '@inertiajs/inertia-vue3';
import HeaderPadrao from '../Componentes/Cabecalho/Padrao';
import SliderDesktop from '../Componentes/Home/Slider/Desktop';
import SliderMobile from '../Componentes/Home/Slider/Mobile';
import { useDialogos } from '../Composables/useDialogos';
import { useQuasar } from 'quasar';
import AlertaAnalise from '../Componentes/AlertaAnalise';
import Rodape from '../Componentes/Rodape';
import ReportaErros from '../Componentes/ReportaErros';
import useGlobals from '../Composables/useGlobals';

useDialogos();
const $q = useQuasar();

const {
    nomeLeiloeiro
} = useGlobals();


defineProps({
    title: String,
    auth: Object,
    banners: Array,
    tab: String ?? 'leiloes',
    description: String,
});

</script>

<template>
    <Head>
        <title>{{(title ? (title + ' - ' ) : '') + nomeLeiloeiro}}</title>
        <meta name="description" :content="description">
    </Head>
    <q-layout view="hHh lpR fFf">
        <reporta-erros>
            <HeaderPadrao class="hide-print" />
        </reporta-erros>
        <q-page-container>
            <reporta-erros>
                <template v-if="tab==='leiloes'">
                    <SliderDesktop :banners="banners" v-if="$q.screen.gt.sm" />
                    <SliderMobile :banners="banners" v-else/>
                </template>
                <slot></slot>
                <alerta-analise />
                <rodape />
            </reporta-erros>
        </q-page-container>
    </q-layout>
</template>
