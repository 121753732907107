<script setup>
import {computed, defineProps, ref} from 'vue';
import { Screen } from 'quasar';
import CardLoteComitente from './CardLoteComitente';
import useRotas from "@/Composables/useRotas";

const route = useRotas();

const props = defineProps({
    lotes: Object,
    comitente: String
});

const slide = ref(0);


function paginarItens(itens, porPagina){
    let paginaAtual = 0;
    let paginas = [];
    itens.forEach(item => {
        if(!paginas[paginaAtual]){
            paginas[paginaAtual] = [item]
        } else if (paginas[paginaAtual].length === porPagina) {
            paginaAtual++;
            paginas[paginaAtual] = [item]
        } else {
            paginas[paginaAtual].push(item)
        }
    })
    return paginas;
}

const lotesPaginados = computed(() => {

    const qtdPorPagina = (Screen.lt.sm) ? 1 : (Screen.lt.md) ? 3 : 5;

    return paginarItens(props.lotes, qtdPorPagina)
})

const isFirstPage = computed(() => slide.value === 0);
const isSecondPage = computed(() => slide.value === 1);
const isThirdPage = computed(() => slide.value === 2);

</script>

<template>
    <q-carousel
        v-model="slide"
        ref="carouselLotesComitente"
        animated
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        class="full-height full-width rounded-borders"

    >
        <template v-slot:control>
            <q-carousel-control
                position="right"
                :offset="[3, 20]"
                class="q-gutter-xs"
            >
                <div class="full-height flex flex-center">
                    <div v-if="isThirdPage
                            || (isSecondPage && lotesPaginados.length === 2)
                            || (isFirstPage && lotesPaginados.length === 1)"
                         class="flex flex-column column items-center">
                        <q-btn
                            size="md"
                            class="bg-secondary"
                            push round text-color="white" icon="mdi-arrow-right"
                            @click="$inertia.visit(route('homeComitente', {idComitente: +props.comitente}))"
                        />
                        <span class="text-bold text-secondary text-caption q-pt-xs" style="font-size: 1.2em">Ver tudo</span>
                    </div>
                    <q-btn
                        v-else
                        size="sm"
                        class="bg-primary"
                        push round text-color="white" icon="mdi-chevron-right"
                        @click="$refs.carouselLotesComitente.next()"
                    />
                </div>
            </q-carousel-control>

            <q-carousel-control
                position="left"
                :offset="[3, 20]"
                class="q-gutter-xs"
            >
                <div class="full-height flex flex-center">
                    <q-btn
                        v-if="!isFirstPage"
                        size="sm"
                        class="bg-primary"
                        push round text-color="white" icon="mdi-chevron-left"
                        @click="$refs.carouselLotesComitente.previous()"
                    />
                </div>
            </q-carousel-control>
        </template>
        <q-carousel-slide v-for="(pagina, index) of lotesPaginados" :key="index" :name="index" class="column no-wrap q-pt-none">
            <div  class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap ">
                <card-lote-comitente v-for="(lote) of pagina" :key="lote.id" :lote-init="lote" :leilao-init="lote.leilao" :mostrar-leilao="true" />
            </div>
        </q-carousel-slide>



    </q-carousel>

</template>
